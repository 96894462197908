<!-- bygger på https://www.digitalocean.com/community/tutorials/vuejs-vue-autocomplete-component -->

<template>
  <div class="input-group">
    <label for="SearchBox" class="sr-only" id="searchCompareLabel">{{
      searchLabel
    }}</label>
    <input
      v-model="searchText"
      @input="onNewInput"
      @keydown.down="onArrowDown"
      @keydown.up="onArrowUp"
      @keydown.esc="onEscape"
      @keydown.enter="onEnter"
      type="search"
      class="ess-searchbox searchPlaceholder form-control autocomplete ac_input searchtextbox"
      aria-controls="SearchBox_AriaLive"
      :placeholder="searchLabel"
      id="SearchBox"
    />
    <div
      v-show="isOpen"
      id="autocomplete-suggestion-list"
      v-clickoutside="handleClickOutside"
    >
      <div class="autocomplete-suggestions" role="listbox" style="">
        <div
          v-for="(result, i) in suggResult"
          :key="i"
          class="autocomplete-suggestion"
          :data-index="i"
          :class="{
            'autocomplete-selected': i === arrowCounter,
          }"
          @click="clickOnSugg(result)"
          @mouseenter="onMouse_enter"
          @mouseleave="onMouse_leave"
          v-html="addStrongToText(result)"
        />
      </div>
    </div>
    <div
      class="suggestions-help sr-only"
      id="SearchBox_AriaLive"
      role="alert"
      aria-atomic="true"
      aria-live="polite"
    ></div>
    <span class="input-group-btn">
      <button
        type="submit"
        class="ess-searchbutton btn btn-submit form-control searchBoxButton"
        id="SearchButton"
        @click="doSearch"
      >
        <SvgIcon IconName="forstoringsglas"></SvgIcon>
        <span class="hidden-xs hidden-sm">{{ searchButtonText }}</span>
        <span class="sr-only hidden-md hidden-lg">{{ searchButtonText }}</span>
      </button>
    </span>
  </div>
</template>

<script>
import api from "../plugins/searchcompareservice";
import SvgIcon from "../misc-components/svgicon.vue";

export default {
  name: "SearchAutocomplete",
  emits: ["search"],
  components: {
    SvgIcon,
  },
  props: {
    searchLabel: {
      type: String,
      required: true,
      default: "hitta",
    },
    searchButtonText: {
      type: String,
      required: true,
      default: "sök",
    },
  },
  data() {
    return {
      searchText: "",
      suggResult: [],
      isOpen: false,
      arrowCounter: -1,
    };
  },

  methods: {
    doSearch() {
      this.isOpen = false;
      this.isLoading = false; // stoppa GetSuggestions från att fylla listan
      this.$emit("search", this.searchText);
    },
    clickOnSugg(result) {
      this.searchText = result;
      this.doSearch();
    },
    async getSuggestions() {
      return;
      if (this.searchText.length < 3) {
        this.isOpen = false;
        this.suggResult = [];
      } else {
        this.isLoading = true;
        await api
          .getSuggestions(this.searchText)
          .then((result) => {
            if (this.isLoading) {
              this.isLoading = false;
              if (result && result.data.length > 0) {
                this.suggResult = result.data
                  ? result.data.map((item) => item.value)
                  : [];
                this.isOpen = true;
              }
            }
          })
          .catch((error) => {
            this.isOpen = false;
            this.isLoading = false;
            console.log(error);
          });
      }
    },
    addStrongToText(wholeText) {
      //Add strongtags to text that match the beginning of the searchtext
      if (
        this.searchText.length > 0 &&
        wholeText.length > 0 &&
        wholeText.length >= this.searchText.length
      ) {
        let firstPart = wholeText.substring(0, this.searchText.length);
        let secondPart = wholeText.substring(firstPart.length);
        return "<strong>" + firstPart + "</strong>" + secondPart;
      }
      return wholeText;
    },
    onNewInput(e) {
      this.arrowCounter = -1;
      //this.waitToStopTyping();
    },
    handleClickOutside() {
      this.isOpen = false;
      this.arrowCounter = -1;
    },
    onArrowDown() {
      if (this.arrowCounter < this.suggResult.length - 1) {
        this.arrowCounter = this.arrowCounter + 1;
      } else {
        this.isOpen = true;
      }
    },
    onArrowUp() {
      if (this.arrowCounter > 0) {
        this.arrowCounter = this.arrowCounter - 1;
      } else {
        this.isOpen = true;
      }
    },
    onEnter() {
      if (this.arrowCounter == -1) {
        //enter är på inputrutan
        this.doSearch();
      } else {
        // enter är i förslagslistan
        this.searchText = this.suggResult[this.arrowCounter];
        this.doSearch();
      }
      this.isOpen = false;
      this.arrowCounter = -1;
    },
    onEscape() {
      this.arrowCounter = -1;
      this.isOpen = false;
    },

    onMouse_enter(mouseEvent) {
      var index = mouseEvent.target.getAttribute("data-index");
      this.arrowCounter = Number(index);
    },
    onMouse_leave() {
      this.arrowCounter = -1;
    },

    fillFromUrl() {
      let url = new URL(window.location.href);
      let params = new URLSearchParams(url.search);
      if (params.has("q")) {
        this.searchText = params.get("q");
      }
    },
    debounce(fn, wait) {
      let timer;
      return function (...args) {
        if (timer) {
          clearTimeout(timer); // clear any pre-existing timer
        }
        const context = this; // get the current context
        timer = setTimeout(() => {
          fn.apply(context, args); // call the function if time expires
        }, wait);
      };
    },
  },
  mounted() {
    this.fillFromUrl();
    //this.waitToStopTyping = this.debounce(() => this.getSuggestions(), 150);
  },
};
</script>
