/*

This is the main entry script file.
Use this to initialize your scripts.

Do NOT make a habit of including code snippets in this file.
create a descriptive module and refer to it instead.

*/
//legacy
// //import "js/legacy/jquery.unobtrusive-ajax.min.js";
import "js/legacy/studera_bootstrap.js";
import "js/legacy/slimmage.js";
import StuderaNu from "js/legacy/script.js";
import "js/legacy/cookies.js";
import ac from "js/legacy/jquery.autocomplete.js";
import "js/legacy/jquery.details.min.js";

ac(jQuery);
window["StuderaNu"] = StuderaNu;

//end legacy

import "@wikimedia/jquery.i18n/src/jquery.i18n.js";
import "@wikimedia/jquery.i18n/src/jquery.i18n.messagestore.js";
import "svgxuse";
import "leaflet";

//import local customs scripts
import globalization from "./components/globalization.js";
import slickinitializer from "./components/slickinitializer.js";
//import quicksearch from "./components/quicksearch.js";
import readspeaker from "./components/readspeaker.js";
import collapsibleheaders from "./components/collapsibleheaders.js";
import mobilemenutoggler from "./components/mobilemenutoggler.js";
import iconappender from "./components/iconappender.js";
import rightmenunavigationpagemenu from "./components/rightnavigationpagemenu.js";

import swedishinstitutionsmap from "./components/pages/swedishinstituitionsmap.js";

//Vue
import { createApp, ref } from "vue";
import { createRouter, useRoute, createWebHistory } from "vue-router";
import searchCompare from "./vue/searchcompare.vue";
import compareEducations from "./vue/compareEducations.vue";
import globalMixin from "./vue/mixin/generalMixin.js";
import vClickOutside from "./vue/directives/vclickoutside.js";

jQuery(() => {
  globalization().done(() => {
    //components using jquery.i18n must wait for translations to load.
    iconappender();
  });
  slickinitializer();
  readspeaker();
  //quicksearch();
  collapsibleheaders();
  rightmenunavigationpagemenu();

  //page specific scripts
  swedishinstitutionsmap();
  mobilemenutoggler();

  /// container => taggnamnet i cshtmlfilen
  var VuecomponentList = [
    { container: "searchcompare", component: searchCompare },
    { container: "compareEducations", component: compareEducations },
  ];

  VuecomponentList.forEach((item) => {
    initVueComponent(item);
  });

  const uhr = window["uhr"];
  uhr.onLoad(StuderaNu.initialize);
  uhr.mainLoaded = true;
  uhr.setLoaded();

  function initVueComponent(item) {
    var pagebottomTAG = item.container,
      pagebottomDOMs = document.querySelectorAll(pagebottomTAG);
    pagebottomDOMs.forEach((pagebottomDOM) => {
      if (pagebottomDOM) {
        var rawoptions = pagebottomDOM.getAttribute("data-settings"),
          options = {};
        if (rawoptions) {
          options = JSON.parse(rawoptions);
        }
        if (item.container == "searchcompare") {
          const routes = [
            {
              path: "/",
              component: searchCompare,
              default: true,
            },
          ];

          let router = createRouter({ history: createWebHistory(), routes });
          const app = createApp(item.component, { ...options });
          app.mixin(globalMixin);
          app.directive("clickoutside", vClickOutside);
          app.use(router);
          //app.config.unwrapInjectedRef = true; //CÅ bortplockade efter vue 3.4..

          if (pagebottomTAG.startsWith(".")) {
            app.mount("#" + pagebottomDOM);
          } else {
            app.mount(item.container);
          }
        } else {
          const app = createApp(item.component, { ...options });
          app.mixin(globalMixin);
          app.directive("clickoutside", vClickOutside);
          //app.config.unwrapInjectedRef = true; //CÅ bortplockade efter vue 3.4..

          if (pagebottomTAG.startsWith(".")) {
            app.mount("#" + pagebottomDOM);
          } else {
            app.mount(item.container);
          }
        }
      }
    });
  }
});
